import { useStaticQuery, graphql } from 'gatsby'
export const usePricingData = lang => {
  const { page } = useStaticQuery(
    graphql`
      query {
        page: allWordpressPage(filter: { wordpress_id: { eq: 1515 } }) {
          edges {
            node {
              wordpress_id
              slug
              title
              content
              polylang_translations {
                polylang_current_lang
                yoast_meta {
                  yoast_wpseo_title
                  yoast_wpseo_metadesc
                }
                acf {
                  title
                  title_mobile
                  subtitle
                  subtitle_mobile
                  role1_title_left
                  role1_title_left_mobile
                  role1_desc_left
                  role1_desc_left_mobile
                  item1_list_left
                  item1_list_left_mobile
                  item2_list_left
                  item2_list_left_mobile
                  item3_list_left
                  item3_list_left_mobile
                  price_field1_left
                  price_field1_left_mobile
                  price_field2_left
                  price_field2_left_mobile
                  price_field3_left
                  price_field3_left_mobile
                  price_field4_left
                  price_field4_left_mobile
                  button_left
                  button_left_mobile
                  role2_title_right
                  role2_title_right_mobile
                  role2_desc_right
                  role2_desc_right_mobile
                  item1_list_right
                  item1_list_right_mobile
                  item2_list_right
                  item2_list_right_mobile
                  item3_list_right
                  item3_list_right_mobile
                  price_field1_right
                  price_field1_right_mobile
                  price_field2_right
                  price_field2_right_mobile
                  price_field3_right
                  price_field3_right_mobile
                  button_right
                  button_right_mobile
                  bottom_field
                  bottom_field_mobile
                  title_pricing_whatsapp
                  title2_pricing_whatsapp
                  title_pricing_whatsapp_mobile
                  title2_pricing_whatsapp_mobile
                  subtitle_pricing_whatsapp
                  subtitle_pricing_whatsapp_mobile
                  button_pricing_whatsapp
                  button_pricing_whatsapp_mobile
                }
              }
            }
          }
        }
      }
    `
  )
  return page.edges[0].node.polylang_translations.find(
    item => item.polylang_current_lang == lang
  )
}
