import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import { OutlineButton } from '../buttons'

import Section from '../Section'
import Triangles from '../../static/img/pricing/pricingTriangles.svg'
import { D, M } from '../breakpoints'
import { usePricingData } from '../../hooks/use-pricing-data'

const BgGlobal = styled.div`
  width: 100%;
  height: 100%;
  background: #332843;
`

const Title = styled.h1`
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
  @media (max-width: 512px) {
    font-size: 38px;
    line-height: 117.5%;
  }
`
const SubTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
`

const TitleSpec = styled.h3`
line-height: 56px;
color: #ffffff;
@media (max-width: 512px) {
  line-height: 117.5%;
}
`

const TagLine = styled.h3`
  font-size: 22px;
  line-height: 29px;
  color: #ac9bc8;
`

const Text = styled.h4`
  font-size: 16px;
  font-weight: normal;
  line-height: 23px;
  text-transform: none;
  color: #e5e5e5;
`

const Separator = styled.hr`
  border: 1px solid #543d6b;
  box-sizing: border-box;
  transform: matrix(1, 0, 0, -1, 0, 0);
  width: 100%;
`

const Hint = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #e5e5e5;
`

const StyledTriangles = styled(Triangles)`
  position: absolute;
  right: 310px;
  top: 119px;
`

const List = styled.ul`
  margin-top: 10px;
  @media (max-width: 512px) {
    margin-bottom: 0px;
  }
`

const ListItem = styled.li`
  margin-bottom: 6px;
  margin-left: -12px;
  @media (max-width: 512px) {
    margin-left: -15px;
  }
`

const Header = props => {
  const {
    acf: {
      title,
      title_mobile,
      subtitle,
      subtitle_mobile,
      role1_title_left,
      role1_title_left_mobile,
      role1_desc_left,
      role1_desc_left_mobile,
      item1_list_left,
      item1_list_left_mobile,
      item2_list_left,
      item2_list_left_mobile,
      item3_list_left,
      item3_list_left_mobile,
      price_field1_left,
      price_field1_left_mobile,
      price_field2_left,
      price_field2_left_mobile,
      price_field3_left,
      price_field3_left_mobile,
      price_field4_left,
      price_field4_left_mobile,
      button_left,
      button_left_mobile,
      role2_title_right,
      role2_title_right_mobile,
      role2_desc_right,
      role2_desc_right_mobile,
      item1_list_right,
      item1_list_right_mobile,
      item2_list_right,
      item2_list_right_mobile,
      item3_list_right,
      item3_list_right_mobile,
      price_field1_right,
      price_field1_right_mobile,
      price_field2_right,
      price_field2_right_mobile,
      price_field3_right,
      price_field3_right_mobile,
      button_right,
      button_right_mobile,
      bottom_field,
      bottom_field_mobile,
    },
  } = usePricingData(props.pageContext.lang)

  return (
    <Section
      mt={[35, 67]}
      px={[15, 0]}
      pt={65}
      pb={47}
      background={<BgGlobal />}
    >
      <Flex flexDirection="column">
        <Flex mb={-15}>
          <Title>{title}</Title>
        </Flex>
        <Flex width={[1, '40%']}>
          <TagLine>{subtitle}</TagLine>
        </Flex>
        <D>
          <StyledTriangles />
        </D>
        <Flex
          flexDirection={['column', 'row']}
          pt={[0, 35]}
          justifyContent="space-between"
        >
          <Flex flexDirection="column" pt={[40, 0]} width={[1, '48%']}>
            <SubTitle>{role1_title_left}</SubTitle>
            <Separator />
            <Flex flexDirection="column">
              <Flex
                flexDirection={['column', 'row']}
                justifyContent="space-between"
              >
                <Flex flexDirection={['column', 'row']}>
                  <Flex flexDirection="column" width={[1, '47%']}>
                    <Text style={{ marginBottom: 0 }}>{role1_desc_left}</Text>
                    <Text>
                      <List>
                        <ListItem> {item1_list_left}</ListItem>
                        <ListItem> {item2_list_left}</ListItem>
                        <ListItem> {item3_list_left}</ListItem>
                      </List>
                    </Text>
                  </Flex>
                  <Flex ml={[0, 50]} flexDirection="column">
                    <Text
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 0,
                      }}
                    >
                      {price_field1_left}
                    </Text>
                    <Flex flexDirection="column">
                      <Flex>
                        <TitleSpec
                          style={{
                            textTransform: 'uppercase',
                            fontSize: 48,
                          }}
                        >
                          {price_field4_left}
                        </TitleSpec>
                        <D>
                          <TitleSpec
                            style={{
                              fontSize: 34,
                              fontWeight: 500,
                              lineHeight: '60px',
                            }}
                          >
                            {price_field2_left}
                          </TitleSpec>
                        </D>
                        <M>
                          <TitleSpec
                            style={{
                              fontSize: 34,
                              fontWeight: 'normal',
                              lineHeight: '60px',
                            }}
                          >
                            {price_field2_left_mobile}
                          </TitleSpec>
                        </M>
                      </Flex>
                      <Text style={{ marginTop: -35 }}>
                        {price_field3_left}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex mt={[0, 0]} mb={29} width={[1, '48%']}>
                <OutlineButton
                  href="https://app.hubtype.com/signup"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="white"
                  borderColor="#FF2B5E"
                  hoverBackground="#FF4B76"
                  style={{
                    background: '#FF2B5E',
                    width: '100%',
                  }}
                >
                  {button_left}
                </OutlineButton>
              </Flex>
              <Separator style={{ marginBottom: 50 }} />
            </Flex>
          </Flex>

          <Flex flexDirection="column" pt={[40, 0]} width={[1, '48%']}>
            <SubTitle>{role2_title_right}</SubTitle>
            <Separator />
            <Flex flexDirection="column">
              <Flex
                flexDirection={['column', 'row']}
                justifyContent="space-between"
              >
                <Flex flexDirection={['column', 'row']}>
                  <Flex flexDirection="column" width={[1, '47%']}>
                    <Text style={{ marginBottom: 0 }}>{role2_desc_right}</Text>
                    <Text>
                      <List>
                        <ListItem>{item1_list_right}</ListItem>
                        <ListItem> {item2_list_right}</ListItem>
                        <ListItem> {item3_list_right}</ListItem>
                      </List>
                    </Text>
                  </Flex>
                  <Flex ml={[0, 50]} flexDirection="column">
                    <Text
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 0,
                      }}
                    >
                      {price_field1_right}
                    </Text>
                    <Flex flexDirection="column">
                      <Flex>
                        <TitleSpec
                          style={{
                            fontSize: 42,
                            fontWeight: 500,
                            lineHeight: '45px',
                            marginTop: 5,
                          }}
                        >
                          {price_field2_right}
                        </TitleSpec>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex mt={[0, 0]} mb={29} width={[1, '38%']}>
                <OutlineButton
                  href="/contact"
                  color="white"
                  borderColor="#FF2B5E"
                  hoverBackground="#FF4B76"
                  style={{
                    background: '#FF2B5E',
                    width: '100%',
                  }}
                >
                  {button_right}
                </OutlineButton>
              </Flex>
              <Separator style={{ marginBottom: 50 }} />
            </Flex>
          </Flex>
        </Flex>
        <Hint>{bottom_field}</Hint>
      </Flex>
    </Section>
  )
}

export default Header
