import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'
import { OutlineButton } from '../buttons'

import Section from '../Section'
import { DesktopFlex, MobileFlex } from '../breakpoints'
import { usePricingData } from '../../hooks/use-pricing-data'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%
  max-width: 100vw;
  z-index: -1;
  background: #6f16ff;
`

const Content = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;

  color: #ffffff;
`

const Title = styled.h2`
  margin-bottom: 1px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  color: #ffffff;
`

const Whatsapp = props => {
  const {
    acf: {
      title_pricing_whatsapp,
      title_pricing_whatsapp_mobile,
      title2_pricing_whatsapp,
      title2_pricing_whatsapp_mobile,
      subtitle_pricing_whatsapp,
      subtitle_pricing_whatsapp_mobile,
      button_pricing_whatsapp,
      button_pricing_whatsapp_mobile,
    },
  } = usePricingData(props.pageContext.lang)
  const { logos, triangles, trianglesMobile } = useStaticQuery(
    graphql`
      query {
        logos: file(relativePath: { eq: "pricing/logos.png" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        triangles: file(relativePath: { eq: "pricing/triangles.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        trianglesMobile: file(
          relativePath: { eq: "pricing/triangle_mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Section background={<Bg />}>
      <Flex className="pricing-whatsapp">
        <Title>
          <div>{title_pricing_whatsapp}</div>
          <div>{title2_pricing_whatsapp}</div>
        </Title>
      </Flex>
      <DesktopFlex py={4}>
        <Flex py={4} width={1 / 2} flexDirection="column">
          <Content
            style={{
              paddingTop: '10px',
              paddingBottom: '25px',
              maxWidth: '350px',
            }}
          >
            {subtitle_pricing_whatsapp}
          </Content>
          <Box py={3}>
            <OutlineButton
              color="white"
              hoverBackground="#ff4d76"
              borderColor="#FF2B5E"
              style={{
                minWidth: '150px',
                minHeight: '40px',
                background: '#FF2B5E',
              }}
              href="/whatsapp-business-api"
            >
              {button_pricing_whatsapp}
            </OutlineButton>
          </Box>
        </Flex>
        <Flex pl={5} width={1 / 2}>
          <Img 
            fixed={logos.childImageSharp.fixed} 
            style={{
              position: 'relative',
              top: '-60px'
            }} 
          />
        </Flex>
        <Img
          style={{
            position: 'absolute',
            bottom: '0px',
            right: '5vw',
            width: 880,
          }}
          fluid={triangles.childImageSharp.fluid}
        />
      </DesktopFlex>
      <MobileFlex py={3}>
        <Flex px={3} flexDirection="column">
          <Flex style={{ position: 'absolute', top: '-15vw', right: '5vw' }}>
            <Img
              style={{
                maxWidth: '110px',
                maxHeight: '90px',
                marginLeft: '25px',
              }}
              fixed={logos.childImageSharp.fixed}
            />
          </Flex>
          <Flex flexDirection="row">
            <Flex>
              <Content>{subtitle_pricing_whatsapp}</Content>
            </Flex>
          </Flex>
          <Box py={4}>
            <OutlineButton
              color="white"
              borderColor="#FF2B5E"
              style={{
                width: '100%',
                background: '#FF2B5E',
              }}
              href="/whatsapp-business-api"
            >
              APPLY NOW
            </OutlineButton>
          </Box>
        </Flex>

        <Img
          style={{
            position: 'absolute',
            bottom: '-10vw',
            right: '20vw',
            width: 120,
            zIndex: 1,
          }}
          fluid={trianglesMobile.childImageSharp.fluid}
        />
      </MobileFlex>
    </Section>
  )
}

export default Whatsapp
