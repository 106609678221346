import React from 'react'
import { BreakpointProvider } from 'react-socks'
import { Flex } from '@rebass/grid'

import Navbar from '../components/Navbar'
import Head from '../components/head'
import Header from '../components/pricing/header'
import Footer from '../components/footer'
import Whatsapp from '../components/pricing/whatsapp'

import { usePricingData } from '../hooks/use-pricing-data'
import '../styles/main.scss'

const Pricing = props => {
  const {
    yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
  } = usePricingData(props.pageContext.lang)

  return (
    <BreakpointProvider>
      <Head
        alternates={props.pageContext.i18n}
        autopilot={props.pageContext.autopilot}
        description={yoast_wpseo_metadesc}
        lang={props.pageContext.lang}
        title={yoast_wpseo_title}
        uri={props.pageContext.relativeUrl}
      />
      <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
        <Navbar
          theme="dark"
          style={{
            background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
            borderBottom: 'none',
          }}
          {...props}
        />
        <Header {...props} />
        <Whatsapp {...props} />
        <Footer {...props} />
      </Flex>
    </BreakpointProvider>
  )
}
export default Pricing
